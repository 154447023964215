// Here you can add other styles
// @import url('https://fonts.googleapis.com/css?family=Nunito:400,300');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
@import '_variables.scss';

body,
html,
* {
	font-family: 'Roboto';
}

.cursor-pointer {
	cursor: pointer;
}

.border-bottom-dotted {
	border-bottom: 2px dashed #44ad13 !important;
}

.border-dotted {
	border-style: dotted;
}
.page-title {
	color: #707780;
	font-size: 25px;
	background-color: white !important;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show > a {
	font-weight: 500;

	svg {
		color: green;
	}
}

.c-sidebar-nav-dropdown .c-sidebar-nav-dropdown {
	padding-left: 5px;
}

.c-sidebar-nav-dropdown-items .c-sidebar-nav-link {
	padding-left: 65px !important;
}

.c-sidebar-nav-dropdown-items
	.c-sidebar-nav-dropdown-items
	.c-sidebar-nav-link {
	padding-left: 75px !important;
}

.c-sidebar
	.c-sidebar-nav-dropdown.c-show
	> .c-sidebar
	.c-sidebar-nav-dropdown.c-show {
	background: rgba(0, 0, 0, 0.4);
}

.is-invalid .custom-radio .custom-control-label::before {
	border: 2px solid red;
}

.form-group {
	&.is-invalid {
		margin-bottom: 3px;

		[class$='-control'] {
			border: 2px red solid;
		}

		.invalid-feedback {
			font-weight: bold;
		}
	}
	label {
		font-size: 11px;
	}
}

.lead-action {
	border-radius: 0;
}

.search-total {
	font-size: 16px;
}

.no-image {
	color: #e6e6e6;
}

.modal-xxl {
	max-width: 90% !important;
}

span.small,
label.small {
	font-size: 13px;
	line-height: 15px;
}

span.xsmall,
label.xsmall {
	font-size: 11px;
	line-height: 12px;
}

//Material UI tabs
.MuiTabs-flexContainer button:focus {
	outline: none;
}

.MuiCheckbox-colorSecondary.Mui-checked {
	color: $primary !important;
}

//Forms
::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-size: 13px;
}
::-moz-placeholder {
	/* Firefox 19+ */
	font-size: 13px;
}
:-ms-input-placeholder {
	/* IE 10+ */
	font-size: 13px;
}
:-moz-placeholder {
	/* Firefox 18- */
	font-size: 13px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
