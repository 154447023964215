// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';

// If you want to add something do it here
@import 'custom';

* {
	--ck-z-default: 100;
	--ck-z-modal: calc(var(--ck-z-default) + 999);
}

.bell-animate {
	border-radius: 100%;
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(45deg, #5c9219, #86c23d);
	width: 34px;
	height: 32px;
	transform-origin: center;
	color: #f5f5f5 !important;
	margin: 0 12px 0 8px;
	position: relative;
}

.cursor-not-allowed {
	cursor: not-allowed !important;
}

.bell-animate::before,
.bell-animate::after {
	content: '';
	position: absolute;

	background: linear-gradient(45deg, #5c9219, #86c23d);

	border-radius: 100%;
}

.bell-animate::before {
	animation: pulseOne 650ms infinite alternate;
	opacity: 0.7;

	z-index: 1;
}

.bell-animate::after {
	animation: pulseSecond 650ms infinite alternate;
	opacity: 0.5;

	z-index: 1;
}

@keyframes pulseOne {
	0% {
		top: 0rem;
		right: 0rem;
		bottom: 0rem;
		left: 0rem;
	}
	100% {
		top: -3px;
		right: -3px;
		bottom: -3px;
		left: -3px;
	}
}

@keyframes pulseSecond {
	0% {
		top: 0rem;
		right: 0rem;
		bottom: 0rem;
		left: 0rem;
	}
	100% {
		top: -6px;
		right: -6px;
		bottom: -6px;
		left: -6px;
	}
}

.bell-animate-text {
	color: #5e9021 !important;
}
